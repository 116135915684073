export const useAuthFetch = () => {

  const authFetch = async (url: string, options: any = {}) => {

    try {
      const { data, error } = await useCsrfFetch(`/api/fetch`, {
        method: "post",
        body: {
          url,
          options,
        },
      });

      if (error?.value && options?.silent !== true) {
        throw new Error(error.value?.data?.message, { cause: error });
      }

      return data.value;
    } catch (error) {
      console.error(error);
      console.log(error);
      throw error;
    }

  };

  return authFetch;
};
